import { gql } from '@apollo/client';
import { getPageSchema, PAGE_QUERY, useFields } from '@uidu/api.js/react';
import BlocksParser from 'components/BlocksParser';
import DefaultPage from 'layouts/DefaultPage';
import { initializeApollo } from 'lib/apollo';
import { NextSeo } from 'next-seo';

const projectId = process.env.NEXT_PUBLIC_PROJECT_ID;

export default function Index({ page, loading, settings, macroareas, needs }) {
  const blocks = page?.blocks;

  const seoBlocks = settings?.blocks.find((block) => block.shortname === 'seo');
  const {
    'meta-image': metaImage,
    'meta-description': metaDescription,
    'meta-keywords': metaKeywords,
  } = useFields(seoBlocks?.fields || []);

  return (
    <DefaultPage loading={loading}>
      <NextSeo
        title={page.metadata?.metaTitle || page?.name}
        description={page.metadata?.metaDescription || metaDescription}
        keywords={page.metadata?.metaKeywords || metaKeywords}
        openGraph={{
          type: 'website',
          images: [{ url: page.metadata?.metaImage?.url || metaImage?.url }],
        }}
        images={[page.metadata?.metaImage?.url || metaImage?.url]}
      />
      {!loading && (
        <BlocksParser blocks={blocks} macroareas={macroareas} needs={needs} />
      )}
    </DefaultPage>
  );
}

export const getStaticProps = async (props) => {
  let loading = true;

  const client = initializeApollo({}, {});

  const res = await client.query({
    query: gql(PAGE_QUERY),
    variables: { slug: 'homepage', projectId },
  });
  const settingsRes = await client.query({
    query: gql(PAGE_QUERY),
    variables: { slug: 'impostazioni-globali-seo', projectId },
  });
  const modelsRes = await client.query({
    query: gql`
      query searchQuery {
        currentWorkspace {
          id
          models {
            id
            name
            modelItems {
              edges {
                node {
                  id
                  name
                  fieldValues: fieldValuesByShortname
                }
              }
            }
          }
        }
      }
    `,
    variables: { model: 'Service' },
  });

  if (!res) return;

  const page = getPageSchema(res?.data?.currentWorkspace?.project?.page);
  const settings = getPageSchema(
    settingsRes?.data?.currentWorkspace?.project?.page,
  );

  const macroarea = modelsRes?.data.currentWorkspace.models.find(
    (m) => m.name === 'Macroarea',
  );
  const macroareas = (
    macroarea?.modelItems.edges.map((edge) => edge.node) || []
  ).filter((macroarea) => macroarea.fieldValues?.published);

  const need = modelsRes?.data.currentWorkspace.models.find(
    (m) => m.name === 'Bisogno',
  );
  const needs = (need?.modelItems.edges.map((edge) => edge.node) || []).filter(
    (need) => need.fieldValues?.published,
  );

  loading = false;
  return {
    revalidate: 86400,
    props: { page, loading, settings, macroareas, needs },
  };
};
